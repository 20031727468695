const PREFIX = "OVERVIEW_";

export const GET_PRAYER_REQUESTS = `${PREFIX}GET_PRAYER_REQUESTS`;
export const GET_RECENT_MESSAGES = `${PREFIX}GET_RECENT_MESSAGES`;
export const GET_RECENT_SERVICES = `${PREFIX}GET_RECENT_SERVICES`;

export const SET_RECENT_MESSAGES_TAG = `${PREFIX}SET_RECENT_MESSAGES_TAG`;
export const SET_RECENT_SERVICES_TAG = `${PREFIX}SET_RECENT_SERVICES_TAG`;
export const SET_PRAYER_REQUESTS_TAG = `${PREFIX}SET_PRAYER_REQUESTS_TAG`;

export const SET_RECENT_MESSAGES_LIMIT = `${PREFIX}SET_RECENT_MESSAGES_LIMIT`;
export const SET_RECENT_SERVICES_LIMIT = `${PREFIX}SET_RECENT_SERVICES_LIMIT`;
export const SET_PRAYER_REQUESTS_LIMIT = `${PREFIX}SET_PRAYER_REQUESTS_LIMIT`;
