import {statusErrorFilter, apiErrorFilter, headers} from './common/apicommons';
import queryString from 'query-string';

export function getMyUser(query) {
  return fetch(`${process.env.GATSBY_API_URL}/user/me?${queryString.stringify(query)}`, {
    method: 'get',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function getVerseOfDay() {
  return fetch(`https://beta.ourmanna.com/api/v1/get/?format=text`).then((res) => res.text());
}

export function updateUser(first, last, email) {
  return fetch(`${process.env.GATSBY_API_URL}/user/me`, {
    method: 'put',
    credentials: 'include',
    body: JSON.stringify({first, last, email}),
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function changePassword(password) {
  return fetch(`${process.env.GATSBY_API_URL}/user/me/password`, {
    method: 'post',
    credentials: 'include',
    body: JSON.stringify({password}),
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function createOrg(org) {
  return fetch(`${process.env.GATSBY_API_URL}/user/me/org`, {
    method: 'post',
    credentials: 'include',
    body: JSON.stringify(org),
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function getOrgs() {
  return fetch(`${process.env.GATSBY_API_URL}/user/me/org`, {
    method: 'get',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function getPremium() {
  return fetch(`${process.env.GATSBY_API_URL}/user/me/premium`, {
    method: 'get',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function acceptOrgInvite(token) {
  return fetch(`${process.env.GATSBY_API_URL}/acceptinvite?token=${token}`, {
    method: 'get',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function purchasePremium(token) {
  return fetch(`${process.env.GATSBY_API_URL}/user/me/purchasePremium`, {
    method: 'post',
    credentials: 'include',
    body: JSON.stringify({token}),
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function purchaseTeam(name, addlseats, token) {
  return fetch(`${process.env.GATSBY_API_URL}/user/me/purchaseTeam`, {
    method: 'post',
    credentials: 'include',
    body: JSON.stringify({name, addlseats, token}),
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function getSubscription() {
  return fetch(`${process.env.GATSBY_API_URL}/user/me/subscription`, {
    method: 'get',
    credentials: 'include',
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function cancelSubscription(subId) {
  return fetch(`${process.env.GATSBY_API_URL}/user/me/subscription/${subId}`, {
    method: 'delete',
    credentials: 'include',
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function renewSubscription(subId) {
  return fetch(`${process.env.GATSBY_API_URL}/user/me/subscription/${subId}`, {
    method: 'post',
    credentials: 'include',
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function getPaymentMethod() {
  return fetch(`${process.env.GATSBY_API_URL}/user/me/paymentmethod`, {
    method: 'get',
    credentials: 'include',
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function updatePaymentMethod(token) {
  return fetch(`${process.env.GATSBY_API_URL}/user/me/paymentmethod`, {
    method: 'post',
    body: JSON.stringify({token}),
    credentials: 'include',
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function getCharges() {
  return fetch(`${process.env.GATSBY_API_URL}/user/me/charges`, {
    method: 'get',
    credentials: 'include',
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function getNextInvoice() {
  return fetch(`${process.env.GATSBY_API_URL}/user/me/charges/upcoming`, {
    method: 'get',
    credentials: 'include',
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}
