// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/josephjung/projects/prolific/church/church-fe3/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-acceptinvite-js": () => import("/Users/josephjung/projects/prolific/church/church-fe3/src/pages/account/acceptinvite.js" /* webpackChunkName: "component---src-pages-account-acceptinvite-js" */),
  "component---src-pages-account-forgotpassword-js": () => import("/Users/josephjung/projects/prolific/church/church-fe3/src/pages/account/forgotpassword.js" /* webpackChunkName: "component---src-pages-account-forgotpassword-js" */),
  "component---src-pages-account-login-js": () => import("/Users/josephjung/projects/prolific/church/church-fe3/src/pages/account/login.js" /* webpackChunkName: "component---src-pages-account-login-js" */),
  "component---src-pages-account-oauthcallback-js": () => import("/Users/josephjung/projects/prolific/church/church-fe3/src/pages/account/oauthcallback.js" /* webpackChunkName: "component---src-pages-account-oauthcallback-js" */),
  "component---src-pages-account-pleaseverify-js": () => import("/Users/josephjung/projects/prolific/church/church-fe3/src/pages/account/pleaseverify.js" /* webpackChunkName: "component---src-pages-account-pleaseverify-js" */),
  "component---src-pages-account-register-js": () => import("/Users/josephjung/projects/prolific/church/church-fe3/src/pages/account/register.js" /* webpackChunkName: "component---src-pages-account-register-js" */),
  "component---src-pages-account-resetpassword-js": () => import("/Users/josephjung/projects/prolific/church/church-fe3/src/pages/account/resetpassword.js" /* webpackChunkName: "component---src-pages-account-resetpassword-js" */),
  "component---src-pages-account-verify-js": () => import("/Users/josephjung/projects/prolific/church/church-fe3/src/pages/account/verify.js" /* webpackChunkName: "component---src-pages-account-verify-js" */),
  "component---src-pages-blog-index-js": () => import("/Users/josephjung/projects/prolific/church/church-fe3/src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-post-our-vision-js": () => import("/Users/josephjung/projects/prolific/church/church-fe3/src/pages/blog/post/our-vision.js" /* webpackChunkName: "component---src-pages-blog-post-our-vision-js" */),
  "component---src-pages-contact-js": () => import("/Users/josephjung/projects/prolific/church/church-fe3/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dashboard-index-js": () => import("/Users/josephjung/projects/prolific/church/church-fe3/src/pages/dashboard/index.js" /* webpackChunkName: "component---src-pages-dashboard-index-js" */),
  "component---src-pages-doc-faq-js": () => import("/Users/josephjung/projects/prolific/church/church-fe3/src/pages/doc/faq.js" /* webpackChunkName: "component---src-pages-doc-faq-js" */),
  "component---src-pages-doc-index-js": () => import("/Users/josephjung/projects/prolific/church/church-fe3/src/pages/doc/index.js" /* webpackChunkName: "component---src-pages-doc-index-js" */),
  "component---src-pages-index-js": () => import("/Users/josephjung/projects/prolific/church/church-fe3/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-premium-js": () => import("/Users/josephjung/projects/prolific/church/church-fe3/src/pages/premium.js" /* webpackChunkName: "component---src-pages-premium-js" */),
  "component---src-pages-pricing-js": () => import("/Users/josephjung/projects/prolific/church/church-fe3/src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("/Users/josephjung/projects/prolific/church/church-fe3/src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-teams-js": () => import("/Users/josephjung/projects/prolific/church/church-fe3/src/pages/teams.js" /* webpackChunkName: "component---src-pages-teams-js" */),
  "component---src-pages-terms-of-service-js": () => import("/Users/josephjung/projects/prolific/church/church-fe3/src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-updates-js": () => import("/Users/josephjung/projects/prolific/church/church-fe3/src/pages/updates.js" /* webpackChunkName: "component---src-pages-updates-js" */),
  "component---src-pages-widget-index-js": () => import("/Users/josephjung/projects/prolific/church/church-fe3/src/pages/widget/index.js" /* webpackChunkName: "component---src-pages-widget-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/josephjung/projects/prolific/church/church-fe3/.cache/data.json")

