const PREFIX = "ACCOUNT_";
export const UPDATE_DETAILS = `${PREFIX}UPDATE_DETAILS`;
export const LOGIN = `${PREFIX}LOGIN`;
export const SESSION_ACTIVE = `${PREFIX}SESSION_ACTIVE`;
export const SESSION_INACTIVE = `${PREFIX}SESSION_INACTIVE`;
export const REGISTER = `${PREFIX}REGISTER`;
export const LOGOUT = `${PREFIX}LOGOUT`;
export const GET_ORGS = `${PREFIX}GET_ORGS`;
export const GET_SUBSCRIPTION = `${PREFIX}GET_SUBSCRIPTION`;
export const CANCEL_SUBSCRIPTION = `${PREFIX}CANCEL_SUBSCRIPTION`;
export const RENEW_SUBSCRIPTION = `${PREFIX}RENEW_SUBSCRIPTION`;
export const GET_PAYMENTMETHOD = `${PREFIX}GET_PAYMENTMETHOD`;
export const UPDATE_PAYMENTMETHOD = `${PREFIX}UPDATE_PAYMENTMETHOD`;
export const GET_CHARGES = `${PREFIX}GET_CHARGES`;
export const GET_NEXTINVOICE = `${PREFIX}GET_NEXTINVOICE`;
export const PURCHASE_PREMIUM = `${PREFIX}PURCHASE_PREMIUM`;
export const PURCHASE_TEAM = `${PREFIX}PURCHASE_TEAM`;
export const GET_SETTINGS = `${PREFIX}GET_SETTINGS`;
export const UPDATE_SETTING = `${PREFIX}UPDATE_SETTING`;
export const SET_PREMIUMSTATUS = `${PREFIX}SET_PREMIUMSTATUS`;