const PREFIX = "ROLE_";
export const GET_ONE = `${PREFIX}GET_ONE`;
export const GET_ALL = `${PREFIX}GET_ALL`;
export const SET_ORDER = `${PREFIX}SET_ORDER`;
export const SET_SELECTED_TAGS = `${PREFIX}SET_SELECTED_TAGS`;
export const CREATE = `${PREFIX}CREATE`;
export const UPDATE = `${PREFIX}UPDATE`;
export const PATCH = `${PREFIX}PATCH`;
export const LINKSYNC = `${PREFIX}LINKSYNC`;
export const DUPLICATE = `${PREFIX}DUPLICATE`;
export const ARCHIVE = `${PREFIX}ARCHIVE`;
export const UNARCHIVE = `${PREFIX}UNARCHIVE`;
export const DELETE = `${PREFIX}DELETE`;
export const GET_TAGS = `${PREFIX}GET_TAGS`;
export const CREATE_TAG = `${PREFIX}CREATE_TAG`;
export const ARCHIVE_TAG = `${PREFIX}ARCHIVE_TAG`;
export const UPDATE_TAG = `${PREFIX}UPDATE_TAG`;
export const TAG_LINK_SYNC = `${PREFIX}TAG_LINK_SYNC`;
export const LINK_ONE = `${PREFIX}LINK_ONE`;
export const UNLINK_ONE = `${PREFIX}UNLINK_ONE`;