import { createStore as createReduxStore, combineReducers, applyMiddleware, compose } from "redux"
import thunk from 'redux-thunk';
import {default as accountReducer} from './account/reducer'
import {default as orgReducer} from './org/reducer'
import {default as contextReducer} from './context/reducer'
import {default as songReducer} from './song/reducer'
import {default as personReducer} from './person/reducer'
import {default as serviceReducer} from './service/reducer'
import {default as sermonReducer} from './sermon/reducer'
import {default as titheReducer} from './tithe/reducer'
import {default as resourceReducer} from './resource/reducer'
import {default as overviewReducer} from './overview/reducer'
import {default as teamReducer} from './team/reducer'
import {default as roleReducer} from './role/reducer'
import {default as widgetReducer} from './widget/reducer'
import {default as notificationReducer} from './notification/reducer'
import {default as entityapiReducer} from './entityapi/reducer'
import persistState from 'redux-localstorage';
import { createLogger } from 'redux-logger'


// Called from the Provider Wrapper.
const createStore = () => {

  const rootReducer = combineReducers({
    account: accountReducer,
    org: orgReducer,
    context: contextReducer,
    song: songReducer,
    person: personReducer,
    service: serviceReducer,
    sermon: sermonReducer,
    tithe: titheReducer,
    resource: resourceReducer,
    team: teamReducer,
    role: roleReducer,
    overview: overviewReducer,
    entityapi: entityapiReducer,
    notification: notificationReducer,
    widget: widgetReducer
  });

  var middlewares = [thunk];

  if (process.env.GATSBY_PROD === 'False') middlewares.push(createLogger());

  if (typeof window !== "undefined") {
    return compose(
      applyMiddleware(...middlewares),
      persistState(null, {key: "oc-store-5"}),
      // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )(createReduxStore)(rootReducer);
  } else {
    return compose(
      applyMiddleware(...middlewares)
    )(createReduxStore)(rootReducer);
  }

};

export default createStore