import deepClone from 'deep-clone'
import * as actionTypes from './actionTypes'
import * as accountActionTypes from '../account/actionTypes'

let initialState = {
  recentServices: {
    lastService: null,
    nextService: null,
    selectedTagId: null
  },
  recentMessages: {
    items: [],
    selectedTagId: null,
    limit: 5
  },
  prayerRequests: {
    items: [],
    selectedTagId: null
  }
};

function reducer(state = initialState, action) {

  switch (action.type) {

    case actionTypes.GET_PRAYER_REQUESTS:
      return {...state, prayerRequests: { ...state.prayerRequests, items: deepClone(action.items)}};
    case actionTypes.SET_PRAYER_REQUESTS_TAG:
      return {...state, recentMessages: { ...state.recentMessages, selectedTagId: action.tagId}};

    case actionTypes.GET_RECENT_SERVICES:
      return {...state, recentServices: { ...state.recentServices, lastService: action.lastService, nextService: action.nextService}};
    case actionTypes.SET_RECENT_SERVICES_TAG:
      return {...state, recentServices: { ...state.recentServices, selectedTagId: action.tagId}};

    case actionTypes.GET_RECENT_MESSAGES:
      return {...state, recentMessages: { ...state.recentMessages, items: deepClone(action.items)}};
    case actionTypes.SET_RECENT_MESSAGES_TAG:
      return {...state, recentMessages: { ...state.recentMessages, selectedTagId: action.tagId}};
    case actionTypes.SET_RECENT_MESSAGES_LIMIT:
      return {...state, recentMessages: { ...state.recentMessages, limit: action.limit}};

    case accountActionTypes.LOGOUT:
      return {...state, ...initialState};
    default:
      return state;
  }

}

export default reducer;
