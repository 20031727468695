const PREFIX = "Org_";
export const CREATE = `${PREFIX}CREATE`;
export const GET_ORGS = `${PREFIX}GET_ORGS`;
export const GET_VERSE_OF_DAY = `${PREFIX}GET_VERSE_OF_DAY`;
export const GET_COLLABORATORS = `${PREFIX}GET_COLLABORATORS`;
export const GET_SUBSCRIPTION = `${PREFIX}GET_SUBSCRIPTION`;
export const CANCEL_SUBSCRIPTION = `${PREFIX}CANCEL_SUBSCRIPTION`;
export const RENEW_SUBSCRIPTION = `${PREFIX}RENEW_SUBSCRIPTION`;
export const GET_PAYMENTMETHOD = `${PREFIX}GET_PAYMENTMETHOD`;
export const UPDATE_PAYMENTMETHOD = `${PREFIX}UPDATE_PAYMENTMETHOD`;
export const GET_CHARGES = `${PREFIX}GET_CHARGES`;
export const GET_NEXTINVOICE = `${PREFIX}GET_NEXTINVOICE`;
export const PATCH = `${PREFIX}PATCH`;

export const GET_INVITES = `${PREFIX}GET_INVITES`;
export const CREATE_INVITE = `${PREFIX}CREATE_INVITE`;
export const CANCEL_INVITE = `${PREFIX}CANCEL_INVITE`;
export const RESEND_INVITE = `${PREFIX}RESEND_INVITE`;