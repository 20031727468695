import * as actionType from './actionTypes';
import * as orgActionTypes from '../org/actions';

let initialState = {
  user: {},

  premium: null,
  paymentMethod: null,
  nextInvoice: null,
  charges: [],
  subscription: null,

  orgs: [],
  loggedIn: false,
  settings: {
    defaultContext: "user-me"
  }
};

function reducer(state = initialState, action) {

  switch (action.type) {

    case actionType.LOGIN:
    case actionType.REGISTER:
    case actionType.SESSION_ACTIVE:
    case actionType.UPDATE_DETAILS:
      return {
        ...state,
        user: {...action.user},
        loggedIn: true
      };
    case actionType.SET_PREMIUMSTATUS:
      return {
        ...state, premium: action.premium
      };
    case actionType.UPDATE_SETTING:
      return {
        ...state,
        settings: {
          ...action.settings,
          [action.key]: action.value
        }
      };
    case actionType.GET_SETTINGS:
      var newSettings = Object.assign({}, state.settings, action.settings);

      return {
        ...state,
        settings: {
          ...newSettings
        }
      };
    case actionType.GET_ORGS:
      return {...state, orgs: action.orgs};

    case actionType.GET_PAYMENTMETHOD:
    case actionType.UPDATE_PAYMENTMETHOD:
      return {...state, paymentMethod: action.paymentMethod};

    case actionType.GET_NEXTINVOICE:
      return {...state, nextInvoice: action.nextInvoice};

    case actionType.GET_SUBSCRIPTION:
      return {...state, subscription: action.subscription};

    case actionType.GET_CHARGES:
      return {...state, charges: action.charges};

    case actionType.SESSION_INACTIVE:
    case actionType.LOGOUT:
      return {...state, ...initialState};

    default:
      return state;
  }

}

export default reducer;
