import {statusErrorFilter, apiErrorFilter, headers} from './common/apicommons';

export function getOrg(orgId) {
  return fetch(`${process.env.GATSBY_API_URL}/org/${orgId}`, {
    method: 'get',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function updateOrg(orgId, name, shortName, timezone) {
  return fetch(`${process.env.GATSBY_API_URL}/org/${orgId}`, {
    method: 'put',
    credentials: 'include',
    body: JSON.stringify({name, shortName, timezone}),
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function patchOrg(orgId, patch) {
  return fetch(`${process.env.GATSBY_API_URL}/org/${orgId}`, {
    method: 'PATCH',
    credentials: 'include',
    body: JSON.stringify(patch),
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function createInvite(orgId, email) {
  return fetch(`${process.env.GATSBY_API_URL}/org/${orgId}/invite`, {
    method: 'post',
    credentials: 'include',
    body: JSON.stringify({orgId, email, redirectUrl: `${window.location.origin}/account/acceptinvite/`}),
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function getInvites(orgId) {
  return fetch(`${process.env.GATSBY_API_URL}/org/${orgId}/invite`, {
    method: 'get',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function resendInvite(orgId, invId) {
  return fetch(`${process.env.GATSBY_API_URL}/org/${orgId}/invite/${invId}/resend`, {
    method: 'get',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function cancelInvite(orgId, invId) {
  return fetch(`${process.env.GATSBY_API_URL}/org/${orgId}/invite/${invId}`, {
    method: 'delete',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function getCollaborators(orgId) {
  return fetch(`${process.env.GATSBY_API_URL}/org/${orgId}/collaborator`, {
    method: 'get',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function updateCollaborator(orgId, colId, role) {
  return fetch(`${process.env.GATSBY_API_URL}/org/${orgId}/collaborator/${colId}`, {
    method: 'update',
    credentials: 'include',
    body: JSON.stringify({role}),
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function addNewDefaultCard(orgId, token) {
  return fetch(`${process.env.GATSBY_API_URL}/org/${orgId}/paymentmethod`, {
    method: 'post',
    body: JSON.stringify({token}),
    credentials: 'include',
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function deleteCollaborator(orgId, colId) {
  return fetch(`${process.env.GATSBY_API_URL}/org/${orgId}/collaborator/${colId}`, {
    method: 'delete',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function deleteOrg(orgId) {
  return fetch(`${process.env.GATSBY_API_URL}/org/${orgId}`, {
    method: 'delete',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function getOrgSubscription(orgId) {
  return fetch(`${process.env.GATSBY_API_URL}/org/${orgId}/subscription`, {
    method: 'get',
    credentials: 'include',
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function cancelOrgSubscription(orgId, subId) {
  return fetch(`${process.env.GATSBY_API_URL}/org/${orgId}/subscription/${subId}`, {
    method: 'delete',
    credentials: 'include',
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function renewOrgSubscription(orgId, subId) {
  return fetch(`${process.env.GATSBY_API_URL}/org/${orgId}/subscription/${subId}`, {
    method: 'post',
    credentials: 'include',
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function getOrgPaymentMethod(orgId) {
  return fetch(`${process.env.GATSBY_API_URL}/org/${orgId}/paymentmethod`, {
    method: 'get',
    credentials: 'include',
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function updateOrgPaymentMethod(orgId, token) {
  return fetch(`${process.env.GATSBY_API_URL}/org/${orgId}/paymentmethod`, {
    method: 'post',
    body: JSON.stringify({token}),
    credentials: 'include',
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function getOrgCharges(orgId) {
  return fetch(`${process.env.GATSBY_API_URL}/org/${orgId}/charges`, {
    method: 'get',
    credentials: 'include',
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function getOrgNextInvoice(orgId) {
  return fetch(`${process.env.GATSBY_API_URL}/org/${orgId}/charges/upcoming`, {
    method: 'get',
    credentials: 'include',
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}
