import * as OrgApi from "../../api/OrgApi";
import * as UserApi from "../../api/UserApi";
import * as actionTypes from './actionTypes'

export const createOrg = (org) => dispatch =>
  UserApi.createOrg(org)
    .then((result) => {
      dispatch({ type: actionTypes.CREATE, newOrg: result.org })
      return result.org;
    });

export const getOrgs = () => dispatch =>
  UserApi.getOrgs()
    .then((result) => dispatch({ type: actionTypes.GET_ORGS, orgs: result.orgs.items }));

export const getVerseOfDay = () => dispatch =>
  UserApi.getVerseOfDay()
    .then((result) => dispatch({ type: actionTypes.GET_VERSE_OF_DAY, verseOfDay: result }));

export const getCollaborators = () => (dispatch, state) =>
  OrgApi.getCollaborators(state().context.id)
    .then((result) => dispatch({ type: actionTypes.GET_COLLABORATORS, collaborators: result.collaborators.items }));

export const getInvites = () => (dispatch, state) =>
  OrgApi.getInvites(state().context.id)
    .then((result) => dispatch({ type: actionTypes.GET_INVITES, invites: result.invites.items }));

export const createInvite = (email) => (dispatch, state) =>
  OrgApi.createInvite(state().context.id, email)
    .then(() => dispatch({ type: actionTypes.CREATE_INVITE }))
    .then(() => getInvites()(dispatch, state));

export const cancelInvite = (inviteId) => (dispatch, state) =>
  OrgApi.cancelInvite(state().context.id, inviteId)
    .then(() => dispatch({ type: actionTypes.CANCEL_INVITE }))
    .then(() => getInvites()(dispatch, state));

export const resendInvite = (inviteId) => (dispatch, state) =>
  OrgApi.resendInvite(state().context.id, inviteId)
    .then(() => dispatch({ type: actionTypes.RESEND_INVITE }))
    .then(() => getInvites()(dispatch, state));

export const patch = (patch) => (dispatch, state) =>
  OrgApi.patchOrg(state().context.id, patch)
    .then((result) => dispatch({ type: actionTypes.PATCH, updatedOrg: result.org }))
    .then(()=> getOrgs()(dispatch, state));

export const getSubscription = () => (dispatch, state) =>
  OrgApi.getOrgSubscription(state().context.id)
    .then((result) => dispatch({ type: actionTypes.GET_SUBSCRIPTION, subscription: result.subscription }));

export const cancelSubscription = () => (dispatch, state) =>
  OrgApi.cancelOrgSubscription(state().context.id, state().org.subscription.id)
    .then(() => dispatch({ type: actionTypes.CANCEL_SUBSCRIPTION }))
    .then(() => Promise.all([
      getSubscription()(dispatch, state),
      getNextInvoice()(dispatch, state)
    ]));

export const renewSubscription = () => (dispatch, state) =>
  OrgApi.renewOrgSubscription(state().context.id, state().org.subscription.id)
    .then(() => dispatch({ type: actionTypes.RENEW_SUBSCRIPTION }))
    .then(() => Promise.all([
      getSubscription()(dispatch, state),
      getNextInvoice()(dispatch, state)
    ]));

export const getPaymentMethod = () => (dispatch, state) =>
  OrgApi.getOrgPaymentMethod(state().context.id)
    .then((result) => dispatch({ type: actionTypes.GET_PAYMENTMETHOD, paymentMethod: result.source }));

export const updatePaymentMethod = (token) => (dispatch, state) =>
  OrgApi.updateOrgPaymentMethod(state().context.id, token)
    .then((result) => dispatch({ type: actionTypes.UPDATE_PAYMENTMETHOD, charges: result.charges }));

export const getCharges = () => (dispatch, state) =>
  OrgApi.getOrgCharges(state().context.id)
    .then((result) => dispatch({ type: actionTypes.GET_CHARGES, charges: result.charges }));

export const getNextInvoice = () => (dispatch, state) =>
  OrgApi.getOrgNextInvoice(state().context.id)
    .then((result) => dispatch({ type: actionTypes.GET_NEXTINVOICE, nextInvoice: result.invoice }));
