import deepClone from 'deep-clone'
import * as actionTypes from './actionTypes'
import * as accountActionTypes from '../account/actionTypes'
import * as contextActionTypes from '../context/actionTypes'

let initialState = {
  items: [],
  tags: [],
  orderDir: 'asc',
  orderBy: 'name',
  selectedTags: [],
  item: null
};

function reducer(state = initialState, action) {

  switch (action.type) {
    case actionTypes.GET_ALL:
      return {...state, items: deepClone(action.items)};
    case actionTypes.GET_ONE:
      return {...state, item: action.item};
    case actionTypes.GET_TAGS:
      return {...state, tags: action.items};
    case actionTypes.CREATE_TAG:
      return {...state, tags: [...state.tags, action.item]};
    case actionTypes.SET_ORDER:
      return {...state,
        orderBy: action.orderBy,
        orderDir: action.orderDir
      };
    case actionTypes.SET_SELECTED_TAGS:
      return {...state, selectedTags: action.selectedTags};
    case actionTypes.LINK_ONE:
      var patchedItem = _.find(state.items, {id: action.entityId});
      var patchedTag = _.find(state.tags, {id: action.linkId});
      if (patchedItem && patchedTag && patchedItem.tags.filter((t) => t.id === patchedTag.id).length === 0) {
        patchedItem.tags.push(patchedTag);
      }
      return {...state, items: deepClone(state.items)};

    case contextActionTypes.SWITCH:
    case accountActionTypes.LOGOUT:
      return {...state, ...initialState};
    default:
      return state;
  }

}

export default reducer;
