import * as userActionTypes from '../account/actionTypes';
import * as actionTypes from '../org/actionTypes';

let initialState = {
  orgs: [],
  collaborators: [],
  invites: [],
  subscription: null,
  paymentMethod: null,
  charges: [],
  nextInvoice: null,
  verseOfDay: null
};

function reducer(state = initialState, action) {

  switch (action.type) {
    case actionTypes.GET_COLLABORATORS:
      return {...state, collaborators: action.collaborators};
    case actionTypes.GET_INVITES:
      return {...state, invites: action.invites};
    case actionTypes.GET_SUBSCRIPTION:
      return {...state, subscription: action.subscription};
    case actionTypes.GET_PAYMENTMETHOD:
      return {...state, paymentMethod: action.paymentMethod};
    case actionTypes.GET_CHARGES:
      return {...state, charges: action.charges};
    case actionTypes.GET_NEXTINVOICE:
      return {...state, nextInvoice: action.nextInvoice};
    case actionTypes.GET_ORGS:
      return {...state, orgs: action.orgs};
    case actionTypes.GET_VERSE_OF_DAY:
      return {...state, verseOfDay: action.verseOfDay};
    case actionTypes.CREATE:
      return {...state, orgs: [...state.orgs, action.newOrg]};
    case userActionTypes.LOGOUT:
      return {...state, ...initialState};
    default:
      return state;
  }

}

export default reducer;
