import * as actionTypes from "./actionTypes";
import * as accountActionTypes from '../account/actionTypes';

let initialState = {
  type: "user",
  id: "me",
  refreshing: false
};

function reducer(state = initialState, action) {

  switch (action.type) {
    case actionTypes.SWITCH:
      return {...state, type: action.contextType, id: action.contextId};
    case actionTypes.SET_REFRESHING:
      return {...state, refreshing: action.refreshing};
    case accountActionTypes.LOGOUT:
      return {...state, ...initialState};
    default:
      return state;
  }

}

export default reducer;
