import deepClone from 'deep-clone'
import * as actionTypes from './actionTypes'
import * as accountActionTypes from '../account/actionTypes'
import * as contextActionTypes from '../context/actionTypes'

let initialState = {
  service: getEntityapiInitial(),
  song: getEntityapiInitial(),
  person : getEntityapiInitial(),
  team: getEntityapiInitial(),
  sermon: getEntityapiInitial(),
  tithe: getEntityapiInitial(),
  resource: getEntityapiInitial()
};

function getEntityapiInitial() { return {
  enabled: false,
  tags: [],
  fields: []
}}

function reducer(state = initialState, action) {

  switch (action.type) {
    case actionTypes.GET_ALL:

      var itemsByCollection = {};

      action.items.forEach((item) => itemsByCollection[item.collection] = item.rules);

      state = {...state,
        service: itemsByCollection['service'] ? deepClone(itemsByCollection['service']) : getEntityapiInitial(),
        song: itemsByCollection['song'] ? deepClone(itemsByCollection['song']) : getEntityapiInitial(),
        person : itemsByCollection['person'] ? deepClone(itemsByCollection['person']) : getEntityapiInitial(),
        team: itemsByCollection['team'] ? deepClone(itemsByCollection['team']) : getEntityapiInitial(),
        sermon: itemsByCollection['sermon'] ? deepClone(itemsByCollection['sermon']) : getEntityapiInitial(),
        tithe: itemsByCollection['tithe'] ? deepClone(itemsByCollection['tithe']) : getEntityapiInitial(),
        resource: itemsByCollection['resource'] ? deepClone(itemsByCollection['resource']) : getEntityapiInitial()
      };

      return {...state};

    case contextActionTypes.SWITCH:
    case accountActionTypes.LOGOUT:
      return {...state, ...initialState};
    default:
      return state;
  }

}

export default reducer;
